import React, {useEffect} from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"
import FeaturesGallery from "../../../components/_V2/FeaturesGallery"

import { getData } from "./data"

import {useLocalizationContext} from "../../../localization/useLocalizationContext";


interface Props {
	className?: string
}

export default function SectionWLCRMFeatures(props: Props) {
	const localizationContext = useLocalizationContext();
	const data = getData(localizationContext.locale);

	let features = data.features;

	useEffect(() => {
		features = data.features;
	}, [data]);

	return (
		<div className={classNames(styles.sectionWlCRMFeatures, props.className)}>
			<FeaturesGallery
				className={styles.wlFeaturesGallery}
				theme={"orange-300"}
				features={features}
			/>
		</div>
	)
}

import React from "react"
import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery"
import { StaticImage } from "gatsby-plugin-image"
import styles from "./styles.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"
import {Locales} from "../../../localization/types";


export const getData = (locale: Locales): {
	features: Array<FeatureGalleryItem>
} => {
	if (locale === 'ru-BY') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Пресеты отчётов",
					desktopTitle: <>Только <br/><span className={styles.fusedSpelling }>эффективность,</span><br/>только хардкор</>,
					tabletTitle: <>Только <span className={styles.fusedSpelling }>эффективность,</span>только хардкор</>,
					mobileTitle: <>Только <br/><span className={styles.fusedSpelling }>эффективность,</span><br/>только хардкор</>,
					desktopContent: "Увеличь количество заказов через приложение. Собери отчет по акциям и пользователям, составь график уведомлений и эффективные инструменты продаж на основе данных.",
					tabletContent: "Увеличь количество заказов через приложение. Собери отчет по акциям и пользователям, составь график уведомлений и эффективные инструменты продаж на основе данных.",
					mobileContent: "Увеличь количество заказов через приложение. Собери отчет по акциям и пользователям, составь график уведомлений и эффективные инструменты продаж на основе данных.",
					mediaContent: (
						<StaticImage
							className={styles.interfaceImage}
							src="./assets/wl-reports-1-BY.png"
							alt={"аналитика по продажам в ресторане"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					detailText: "Подробнее",
					detailLink: pagesLinks.reports,
				},
				{
					desktopContentWidth: 490,
					tabName: "Генератор отчётов",
					desktopTitle: "Найди ту самую (цифру)",
					desktopContent:
						"Конструктор отчетов поможет составить персональную выдачу по необходимым данным. Будет это две строки или детальный анализ за квартал — решать самому.",
					mediaContent: (
						<StaticImage
							className={styles.interfaceImage}
							src="./assets/wl-reports-2-BY.png"
							alt={"анализ работы ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					detailText: "Подробнее",
					detailLink: pagesLinks.reports,
				},
			] as Array<FeatureGalleryItem>,
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Есептер пресеттері",
					desktopTitle: "Тиімді, басқаша қалай болсын?",
					desktopContent: "Қосымша арқылы тапсырыстар санын ұлғайтыңыз. Акциялар мен пайдаланушылар бойынша есепті жинаңыз, деректер негізінде хабарламалар кестесін және тиімді сату құралдарын жасаңыз.",
					mediaContent: (
						<StaticImage
							className={styles.interfaceImage}
							src="./assets/wl-reports-1-KZ.png"
							alt={"аналитика по продажам в ресторане"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					detailText: "Толығырақ",
					detailLink: pagesLinks.reports,
				},
				{
					desktopContentWidth: 490,
					tabName: "Есептер генераторы",
					desktopTitle: "Дәл сол (цифрды) табыңыз",
					desktopContent: "Есептер конструкторы қажетті деректер бойынша дербес беруді құруға көмектеседі. Бұл екі жол немесе тоқсан үшін толық талдау бола ма - өзіңіз шешесіз.",
					mediaContent: (
						<StaticImage
							className={styles.interfaceImage}
							src="./assets/wl-reports-2-KZ.png"
							alt={"анализ работы ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					detailText: "Толығырақ",
					detailLink: pagesLinks.reports,
				},
			] as Array<FeatureGalleryItem>,
		}
	}

	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Пресеты отчётов",
					desktopTitle: "Эффективно, а как ещё?",
					desktopContent: "Увеличь количество заказов через приложение. Собери отчет по акциям и пользователям, составь график уведомлений и эффективные инструменты продаж на основе данных.",
					mobileContent: "Увеличь количество заказов через приложение. Собери отчет по акциям и пользователям, составь график уведомлений и эффективные инструменты продаж на основе данных.",
					mediaContent: (
						<StaticImage
							className={styles.interfaceImage}
							src="./assets/wl-reports-1-KZ.png"
							alt={"аналитика по продажам в ресторане"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					detailText: "Подробнее",
					detailLink: pagesLinks.reports,
				},
				{
					desktopContentWidth: 490,
					tabName: "Генератор отчётов",
					desktopTitle: "Найди ту самую (цифру)",
					desktopContent: "Конструктор отчетов поможет составить персональную выдачу по необходимым данным. Будет это две строки или детальный анализ за квартал — решать самому.",
					mobileContent: "Конструктор отчетов поможет составить персональную выдачу по необходимым данным. Будет это две строки или детальный анализ за квартал — решать самому.",
					mediaContent: (
						<StaticImage
							className={styles.interfaceImage}
							src="./assets/wl-reports-2-KZ.png"
							alt={"анализ работы ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					detailText: "Подробнее",
					detailLink: pagesLinks.reports,
				},
			] as Array<FeatureGalleryItem>,
		}
	}
	return {
		features: [
			{
				desktopContentWidth: 490,
				tabName: "Пресеты отчётов",
				desktopTitle: "Анализируй \nи действуй",
				desktopContent:
					"Увеличь количество заказов через приложение для кафе и ресторанов. Собери отчет по акциям и пользователям, составь график уведомлений и эффективные инструменты продаж на основе данных.",
				mediaContent: (
					<StaticImage
						className={styles.interfaceImage}
						src="./assets/wl-reports-1.png"
						alt={"аналитика по продажам в ресторане"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				detailText: "Подробнее",
				detailLink: pagesLinks.reports,
			},
			{
				desktopContentWidth: 490,
				tabName: "Генератор отчётов",
				desktopTitle: "Найди ту самую (цифру)",
				desktopContent:
					"Генератор отчетов поможет составить персональную выдачу по необходимым данным. Будет это две строки или детальный анализ за квартал — решать самому.",
				mediaContent: (
					<StaticImage
						className={styles.interfaceImage}
						src="./assets/wl-reports-2.png"
						alt={"анализ работы ресторана"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				detailText: "Подробнее",
				detailLink: pagesLinks.reports,
			},
		] as Array<FeatureGalleryItem>,
	}
}

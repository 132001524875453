import React, { useState } from "react"
import classNames from "classnames"

import TextBillboard from "../../../components/_V2/TextBillboard"
import styles from "./styles.module.scss"
import DesignCtaForm from "../../../components/_V2/DesignCtaForm"
import { requestForWLDesignOffer } from "../../../apiRequests/callToAction"

import { getData } from "./data"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";


interface Props {
	className?: string
}

export default function SectionWLUniqDesign(props: Props) {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const localizationContext = useLocalizationContext()
	const data = getData(localizationContext.locale)

	const handleModalStatus = () => {
		setIsModalOpen(!isModalOpen)
	}

	return (
		<>
			<div className={classNames(styles.sectionWlUniqDesign, props.className)}>
				<TextBillboard
					className={classNames(styles.sectionContent, styles.themeFuchsia)}
					data={data}
					action={handleModalStatus}
				/>
			</div>

			<DesignCtaForm
				isOpen={isModalOpen}
				onClose={handleModalStatus}
				request={requestForWLDesignOffer}
			/>
		</>
	)
}

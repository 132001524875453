import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery"
import mediaContent from "./mediaContent.module.scss"
import style from "./style.module.scss"
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales): {
	features: Array<FeatureGalleryItem>
} => {
	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 510,
					tabName: "Уведомления",
					desktopTitle: "Пиши своим гостям",
					mobileTitle: "Пиши своим гостям",
					desktopContent:
						<>Уведомляй о новых акциях и статусе заказа через <span className={style.fusedSpelling }>push-сообщения</span>. Все уведомления можно настроить по&nbsp;расписанию для повышения количества заказов в&nbsp;определённые часы.</>,
					mobileContent:
						<>Уведомляй о новых акциях и статусе заказа через push-сообщения. Все уведомления можно настроить по&nbsp;расписанию для повышения количества заказов в&nbsp;определённые часы.</>,
					mediaContent: (
						<div className={mediaContent.images}>
							<StaticImage
								className={mediaContent.images__phone}
								src="./assets/app-phone-KZ.png"
								alt={"уведомления в приложении для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={mediaContent.images__notifications}
								src="./assets/app-notifications-KZ.png"
								alt={"уведомления в приложении для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
				},
				{
					desktopContentWidth: 510,
					tabName: "Рассылки",
					desktopTitle: "Поток заказов, как по расписанию",
					mobileTitle: "Поток заказов, как по расписанию",
					desktopContent:
						"Используй таргетированные рассылки по полу и возрасту, уведомляй о новых акциях через push-сообщения.",
					mobileContent:
						"Используй таргетированные рассылки по полу и возрасту, уведомляй о новых акциях через push-сообщения.",
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/app-ui-1-KZ.png"
							alt={"таргетированные рассылки в приложении"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 510,
					tabName: "Таргетинг",
					desktopTitle: "Делай персональное предложение",
					mobileTitle: "Делай персональное предложение",
					desktopContent: "Выдели нужную аудиторию — по полу, возрасту, дате рождения, по накопленным бонусам и среднему чеку. Удели внимание каждому с помощью персонифицированных рассылок.",
					tabletContent: "Выдели нужную аудиторию — по полу, возрасту, дате рождения, по накопленным бонусам и среднему чеку. Удели внимание каждому с помощью персонифицированных рассылок.",
					mobileContent: "Выдели нужную аудиторию — по полу, возрасту, дате рождения, по накопленным бонусам и среднему чеку. Удели внимание каждому с помощью персонифицированных рассылок.",
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/app-ui-2-KZ.png"
							alt={"сегментация базы гостей ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
			] as Array<FeatureGalleryItem>,
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 510,
					tabName: "Хабарламалар",
					desktopTitle: "Өз қонақтарыңызға жазыңыз",
					mobileTitle: "Пиши своим гостям",
					desktopContent:
						<>Жаңа акциялар мен тапсырыс мәртебесі туралы  <span className={style.fusedSpelling }>push-хабарламалар</span> арқылы хабарлаңыз. Белгілі бір сағаттарда тапсырыстар санын арттыру үшін барлық хабарламаларды кесте бойынша теңшеуге болады.</>,
					mobileContent:
						<>Жаңа акциялар мен тапсырыс мәртебесі туралы push-хабарламалар арқылы хабарлаңыз. Белгілі бір сағаттарда тапсырыстар санын арттыру үшін барлық хабарламаларды кесте бойынша теңшеуге болады.</>,
					mediaContent: (
						<div className={mediaContent.images}>
							<StaticImage
								className={mediaContent.images__phone}
								src="./assets/app-phone-KZ.png"
								alt={"уведомления в приложении для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={mediaContent.images__notifications}
								src="./assets/app-notifications-KZ.png"
								alt={"уведомления в приложении для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
				},
				{
					desktopContentWidth: 510,
					tabName: "Жіберілімдер",
					desktopTitle: "Тапсырыстар ағыны кестегідей сияқты",
					desktopContent:
						"Жынысы мен жасы бойынша таргеттелген жіберілімдерді пайдаланыңыз, жаңа акциялар туралы push-хабарламалар арқылы хабарлаңыз.",
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/app-ui-1-KZ.png"
							alt={"таргетированные рассылки в приложении"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 510,
					tabName: "Таргетинг",
					desktopTitle: "Дербес ұсынысты жасаңыз",
					desktopContent: "Қажетті аудиторияны бөлектеңіз - жынысы, жасы, туған күні бойынша, жинақталған бонустар мен орташа чек бойынша. Дербестендірілген жіберілімдер арқылы әрқайсысына назар аударыңыз.",
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/app-ui-2-KZ.png"
							alt={"сегментация базы гостей ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
			] as Array<FeatureGalleryItem>,
		}
	}

	if (locale === 'ru-BY') {
		return {
			features: [
				{
					desktopContentWidth: 510,
					tabName: "Уведомления",
					desktopTitle: "Будь на связи с гостями",
					mobileTitle: "Будь на связи с гостями",
					desktopContent:
						<>Уведомляй о новых акциях и статусе заказа через <span className={style.fusedSpelling }>push-сообщения</span>. Все уведомления можно настроить по расписанию для повышения количества заказов в определённые часы.</>,
					mobileContent:
						<>
							Уведомляй о новых акциях
							и статусе заказа через push-сообщения.
							Все уведомления можно настроить
							по расписанию для повышения количества
							заказов в определённые часы.
						</>,
					mediaContent: (
						<div className={mediaContent.images}>
							<StaticImage
								className={mediaContent.images__phone}
								src="./assets/app-phone-BY.png"
								alt={"уведомления в приложении для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={mediaContent.images__notifications}
								src="./assets/app-notifications-BY.png"
								alt={"уведомления в приложении для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
				},
				{
					desktopContentWidth: 510,
					tabName: "Пуши по расписанию",
					desktopTitle: "Поток заказов, как по расписанию",
					mobileTitle: "Поток заказов, как по расписанию",
					desktopContent:
						"Используй таргетированные рассылки по полу и возрасту, уведомляй о новых акциях через push-сообщения.",
					mobileContent:
						"Используй таргетированные рассылки по полу и возрасту, уведомляй о новых акциях через push-сообщения.",
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/app-ui-1-BY.png"
							alt={"таргетированные рассылки в приложении"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 510,
					tabName: "Таргетинг",
					desktopTitle: "Делай персональное предложение",
					mobileTitle: "Делай персональное предложение",
					desktopContent:
						"Выдели нужную аудиторию — по полу, возрасту, дате рождения, по накопленным бонусам и среднему чеку. Удели внимание каждому и с помощью персонифицированных рассылок.",
					mobileContent:
						"Выдели нужную аудиторию — по полу, возрасту, дате рождения, по накопленным бонусам и среднему чеку. Удели внимание каждому и с помощью персонифицированных рассылок.",
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/app-ui-2-BY.png"
							alt={"сегментация базы гостей ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
				},
			] as Array<FeatureGalleryItem>,
		}
	}

	return {
		features: [
			{
				desktopContentWidth: 510,
				tabName: "Уведомления",
				desktopTitle: "Настрой канал общения с гостями",
				mobileTitle: "Настрой канал общения с гостями",
				desktopContent:
					"Уведомляй о новых акциях и статусе заказа через push-сообщения. Все уведомления можно настроить по расписанию для повышения количества заказов в определённые часы.",
				mobileContent:
					"Уведомляй о новых акциях и статусе заказа через push-сообщения. Все уведомления можно настроить по расписанию для повышения количества заказов в определённые часы.",
				mediaContent: (
					<div className={mediaContent.images}>
						<StaticImage
							className={mediaContent.images__phone}
							src="./assets/app-phone.png"
							alt={"уведомления в приложении для кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
						<StaticImage
							className={mediaContent.images__notifications}
							src="./assets/app-notifications.png"
							alt={"уведомления в приложении для кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
			},
			{
				desktopContentWidth: 510,
				tabName: "Рассылки",
				desktopTitle: "Формируй поток заказов \nпо расписанию",
				mobileTitle: "Формируй поток заказов \nпо расписанию",
				desktopContent:
					"Используй таргетированные рассылки по полу и возрасту, уведомляй о новых акциях через push-сообщения.",
				mobileContent:
					"Используй таргетированные рассылки по полу и возрасту, уведомляй о новых акциях через push-сообщения.",
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/app-ui-1.png"
						alt={"таргетированные рассылки в приложении"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
			},
			{
				desktopContentWidth: 510,
				tabName: "Таргетинг",
				desktopTitle: "Общайся лично",
				mobileTitle: "Общайся лично",
				desktopContent:
					"Выдели нужную аудиторию — по полу, возрасту, дате рождения, накопленным бонусам и среднему чеку. Удели внимание каждому и с помощью персонифицированных рассылок.",
				mobileContent:
					"Выдели нужную аудиторию — по полу, возрасту, дате рождения, накопленным бонусам и среднему чеку. Удели внимание каждому и с помощью персонифицированных рассылок.",
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/app-ui-2.png"
						alt={"сегментация базы гостей ресторана"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
			},
		] as Array<FeatureGalleryItem>,
	}
}

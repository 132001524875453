import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery"
import mediaContent from "./mediaContent.module.scss"
import classNames from "classnames";

import {Locales} from "../../../localization/types";

const telegramBotLink = 'https://t.me/omletresto_bot?start=QRSITE';
const telegramBotLinkKZ = 'https://t.me/omletresto_bot?start=QRSITEKZ';
const telegramBotLinkBY = 'https://t.me/omletresto_bot?start=QRSITEBY';

export const getData = (locale: Locales): {
	features: Array<FeatureGalleryItem>
} => {
	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 530,
					tabName: "Telegram-бот",
					desktopTitle: "Telegram-бот для заказов",
					desktopContent: (
						<span className={mediaContent.smallDescription}>
							Предложи гостям удобный способ заказа прямо
							из&nbsp;Telegram. Бот покажет аппетитные фотографии блюд,
							оформит доставку или самовывоз прямо в&nbsp;мессенджере.
							Назначил встречу с&nbsp;друзьями в&nbsp;одном диалоге,
							в&nbsp;соседнем&nbsp;&mdash; заказал для неё пиццу.
						</span>
					),
					mobileContent: (
						<>
							Предложи гостям удобный способ заказа прямо
							из&nbsp;Telegram. Бот покажет аппетитные фотографии блюд,
							оформит доставку или самовывоз прямо в мессенджере.
							Назначил встречу с друзьями в одном диалоге,
							в&nbsp;соседнем&nbsp;&mdash; заказал для неё пиццу.
						</>
					),
					mediaContent: (
						<>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_logo_telegram)}
								src="./assets/telegram-logo.png"
								alt={"уведомления в приложении для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					detailText: 'Посмотреть пример бота',
					detailLink: telegramBotLinkKZ
				},
				{
					desktopContentWidth: 530,
					tabName: "Заказ",
					desktopTitle: <>Бот поможет <br/>принять&nbsp;заказ</>,
					tabletTitle: <>Бот поможет принять&nbsp;заказ</>,
					mobileTitle: <>Бот поможет принять&nbsp;заказ</>,
					desktopContent: (
						<span className={mediaContent.smallDescription}>
							Telegram-бот не отвлекается и не пропустит сообщение
							гостя. Готов без перерыва и выходных рассказывать
							о&nbsp;заведении, предлагать меню и оформлять заказы.
							Понравится тем, кто не любит звонить и писать живым
							сотрудникам.
						</span>
					),
					mobileContent: (
						<p>
							Telegram-бот не&nbsp;отвлекается и не пропустит сообщение
							гостя. Готов без перерыва и&nbsp;выходных рассказать о&nbsp;заведении,
							предложить&nbsp;меню и оформить заказ.&nbsp;Привлечет
							тех, кто не&nbsp;любит звонить и&nbsp;писать живым сотрудникам.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_order, mediaContent.image_phone_desktop)}
								src="./assets/phones-order-KZ.png"
								alt={"таргетированные рассылки в приложении"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_order, mediaContent.image_phone_tablet)}
								src="./assets/phones-order-tablet-KZ.png"
								alt={"таргетированные рассылки в приложении"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_order, mediaContent.image_phone_mobile)}
								src="./assets/phones-order-mobile-KZ.png"
								alt={"таргетированные рассылки в приложении"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					detailText: 'Посмотреть пример бота',
					detailLink: telegramBotLinkKZ
				},
				{
					desktopContentWidth: 530,
					tabName: "Коммуникация",
					desktopTitle: <>Пользователь <br/>зашёл в чат</>,
					tabletTitle: <>Пользователь зашёл в чат</>,
					mobileTitle:  <>Пользователь <br/>зашёл в чат</>,
					desktopContent: (
						<>
							Держи связь с&nbsp;гостями, отвечай на вопросы и&nbsp;предложения
							там, где не&nbsp;достаточно ответов бота. Для каждого гостя
							создается отдельный чат, где хранится история переписки.
							Рассылай моментальные уведомления прямо в Telegram,
							чтобы&nbsp;гости точно заметили новую акцию.
						</>
					),
					tabletContent: (
						<>
							Держи обратную связь с гостями, подключайся отвечай на вопросы
							и&nbsp;предложения, там где не достаточно ответов бота. Для каждого гостя
							создается отдельный чат, где хранится история переписки.
							Рассылай моментальные уведомления прямо в Telegram,
							чтобы&nbsp;гости точно заметили новую акцию.
						</>
					),
					mobileContent: (
						<>
							Держи обратную связь с&nbsp;гостями, подключайся отвечай на вопросы
							и&nbsp;предложения, там где не достаточно ответов бота. Для каждого гостя
							создается отдельный чат, где хранится история переписки.
							Рассылай моментальные уведомления прямо в Telegram,
							чтобы&nbsp;гости точно заметили новую акцию.
						</>
					),
					mediaContent: (
						<>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_dialog, mediaContent.image_phone_dialog_desktop)}
								src="./assets/phones-dialog-KZ.png"
								alt={"сегментация базы гостей ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_dialog, mediaContent.image_phone_dialog_tablet)}
								src="./assets/phones-dialog-tablet-KZ.png"
								alt={"сегментация базы гостей ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_dialog, mediaContent.image_phone_dialog_mobile)}
								src="./assets/phones-dialog-mobile-KZ.png"
								alt={"сегментация базы гостей ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					detailText: 'Посмотреть пример бота',
					detailLink: telegramBotLinkKZ
				},
				{
					desktopContentWidth: 503,
					tabName: "Дизайн",
					desktopTitle: <>Будь собой,<br/>будь стильным</>,
					tabletTitle: <>Будь собой, будь стильным</>,
					mobileTitle: <>Будь собой,<br/>будь стильным</>,
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Настраивай изображения и тексты для бота в фирменном
							стиле&nbsp;&mdash; развивай единый образ бренда. Гости, которые
							уже пользовались сайтом или приложением, узнают
							и&nbsp;запоминают заведение.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_design, mediaContent.image_phone_design_desktop)}
								src="./assets/phones-design-KZ.png"
								alt={"сегментация базы гостей ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_design, mediaContent.image_phone_design_tablet)}
								src="./assets/phones-design-tablet-KZ.png"
								alt={"сегментация базы гостей ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_design, mediaContent.image_phone_design_mobile)}
								src="./assets/phones-design-mobile-KZ.png"
								alt={"сегментация базы гостей ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					detailText: 'Посмотреть пример бота',
					detailLink: telegramBotLinkKZ
				},
			] as Array<FeatureGalleryItem>,
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 530,
					tabName: "Telegram-бот",
					desktopTitle:<>Тапсырыстарға арналған<br />Telegram-бот</>,
					desktopContent: (
						<span className={mediaContent.smallDescription}>
							Қонақтарға тікелей Telegram-нан ыңғайлы тапсырыс тәсілін ұсыныңыз. Бот тағамдардың тәбет ашатын фотосуреттерін көрсетеді, жеткізуді немесе өзімен әкетуді тікелей мессенджерде ресімдейді.
							Бір диалогта достарынмен кездесуді тағайындады, екіншісінде — пиццаға тапсырыс берді.
						</span>
					),
					mediaContent: (
						<>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_logo_telegram)}
								src="./assets/telegram-logo.png"
								alt={"уведомления в приложении для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					detailText: 'Боттың мысалын қараңыз',
					detailLink: telegramBotLinkKZ
				},
				{
					desktopContentWidth: 530,
					tabName: "Тапсырыс",
					desktopTitle: <>Бот тапсырысты<br/>қабылдауға көмектеседі</>,
					desktopContent: (
						<span className={mediaContent.smallDescription}>
							Telegram-бот енжар болмайды және қонақтың хабарламасын өткізіп алмайды. Үзіліссіз және
							демалыссыз мекеме туралы айтуға, мәзірді ұсынуға және тапсырыстарды ресімдеуге дайын.
							Тірі қызметкерлерге қоңырау шалуды және жазуды ұнатпайтындарға ұнайды.
						</span>
					),
					mediaContent: (
						<>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_order, mediaContent.image_phone_desktop)}
								src="./assets/phones-order-KZ.png"
								alt={"таргетированные рассылки в приложении"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_order, mediaContent.image_phone_tablet)}
								src="./assets/phones-order-tablet-KZ.png"
								alt={"таргетированные рассылки в приложении"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_order, mediaContent.image_phone_mobile)}
								src="./assets/phones-order-mobile-KZ.png"
								alt={"таргетированные рассылки в приложении"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					detailText: 'Боттың мысалын қараңыз',
					detailLink: telegramBotLinkKZ
				},
				{
					desktopContentWidth: 530,
					tabName: "Байланысу",
					desktopTitle: <>Пайдаланушы чатқа кірді</>,
					desktopContent: (
						<>
							Қонақтармен кері байланысты сақтаңыз, боттың жауаптары жеткілікті болмайтын жерде қосылыңыз,
							сұрақтар мен ұсыныстарға жауап беріңіз. Әр қонақ үшін хат-хабар тарихы сақталатын бөлек чат
							құрылады. Қонақтар жаңа акцияны нақты байқауы үшін тікелей Telegram-да лездік хабарламаларды
							жіберіңіз.
						</>
					),
					mediaContent: (
						<>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_dialog, mediaContent.image_phone_dialog_desktop)}
								src="./assets/phones-dialog-KZ.png"
								alt={"сегментация базы гостей ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_dialog, mediaContent.image_phone_dialog_tablet)}
								src="./assets/phones-dialog-tablet-KZ.png"
								alt={"сегментация базы гостей ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_dialog, mediaContent.image_phone_dialog_mobile)}
								src="./assets/phones-dialog-mobile-KZ.png"
								alt={"сегментация базы гостей ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					detailText: 'Боттың мысалын қараңыз',
					detailLink: telegramBotLinkKZ
				},
				{
					desktopContentWidth: 503,
					tabName: "Дизайн",
					desktopTitle: <>Өзіңіздей болыңыз,<br />стильді болыңыз</>,
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Бот үшін кескіндер мен мәтіндерді фирмалық стильде теңшеңіз — брендтің бірыңғай бейнесін
							дамытыңыз. Сайтты немесе қосымшаны пайдаланған қонақтар, мекемені танып, естеріне қалдырады.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_design, mediaContent.image_phone_design_desktop)}
								src="./assets/phones-design-KZ.png"
								alt={"сегментация базы гостей ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_design, mediaContent.image_phone_design_tablet)}
								src="./assets/phones-design-tablet-KZ.png"
								alt={"сегментация базы гостей ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_design, mediaContent.image_phone_design_mobile)}
								src="./assets/phones-design-mobile-KZ.png"
								alt={"сегментация базы гостей ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					detailText: 'Боттың мысалын қараңыз',
					detailLink: telegramBotLinkKZ
				},
			] as Array<FeatureGalleryItem>,
		}
	}

	if (locale === 'ru-BY') {
		return {
			features: [
				{
					desktopContentWidth: 530,
					tabName: "Telegram-бот",
					desktopTitle: "Telegram-бот для заказов",
					desktopContent: (
						<span className={mediaContent.smallDescription}>
							Предложи гостям удобный способ заказа прямо
							из&nbsp;Telegram. Бот покажет аппетитные фотографии блюд,
							оформит доставку или самовывоз прямо в&nbsp;мессенджере.
							Назначил встречу с&nbsp;друзьями в&nbsp;одном диалоге,
							в&nbsp;соседнем&nbsp;&mdash; заказал для неё пиццу.
						</span>
					),
					mobileContent: (
						<>
							Предложи гостям удобный способ заказа прямо
							из&nbsp;Telegram. Бот покажет аппетитные фотографии блюд,
							оформит доставку или самовывоз прямо в мессенджере.
							Назначил встречу с друзьями в одном диалоге,
							в&nbsp;соседнем&nbsp;&mdash; заказал для неё пиццу.
						</>
					),
					mediaContent: (
						<>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_logo_telegram)}
								src="./assets/telegram-logo.png"
								alt={"уведомления в приложении для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					detailText: 'Посмотреть пример бота',
					detailLink: telegramBotLinkBY
				},
				{
					desktopContentWidth: 530,
					tabName: "Заказ",
					desktopTitle: <>Получай больше заказов</>,
					tabletTitle: <>Получай больше заказов</>,
					mobileTitle: <>Получай<br/>больше заказов</>,
					desktopContent: (
						<span className={mediaContent.smallDescription}>
							Telegram-бот не отвлекается и не пропустит сообщение
							гостя. Готов без перерыва и выходных рассказывать
							о&nbsp;заведении, предложить меню и оформить заказ.
							Привлечет тех, кто не любит звонить и писать живым
							сотрудникам.
						</span>
					),
					mobileContent: (
						<p>
							Telegram-бот не&nbsp;отвлекается и не пропустит сообщение
							гостя. Готов без перерыва и&nbsp;выходных рассказать о&nbsp;заведении,
							предложить&nbsp;меню и оформить заказ.&nbsp;Привлечет
							тех, кто не&nbsp;любит звонить и&nbsp;писать живым сотрудникам.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_order, mediaContent.image_phone_desktop)}
								src="./assets/phones-order-BY.png"
								alt={"таргетированные рассылки в приложении"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_order, mediaContent.image_phone_tablet)}
								src="./assets/phones-order-tablet-BY.png"
								alt={"таргетированные рассылки в приложении"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_order, mediaContent.image_phone_mobile)}
								src="./assets/phones-order-mobile-BY.png"
								alt={"таргетированные рассылки в приложении"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					detailText: 'Посмотреть пример бота',
					detailLink: telegramBotLinkBY
				},
				{
					desktopContentWidth: 530,
					tabName: "Коммуникация",
					desktopTitle: "Пиши в чат",
					mobileTitle: "Пиши в чат",
					desktopContent: (
						<>
							Держи обратную связь с гостями, подключайся отвечай на вопросы
							и&nbsp;предложения, там где не достаточно ответов бота. Для каждого гостя
							создается отдельный чат, где хранится история переписки.
							Рассылай моментальные уведомления прямо в Telegram,
							чтобы&nbsp;гости точно заметили новую акцию.
						</>
					),
					mediaContent: (
						<>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_dialog, mediaContent.image_phone_dialog_desktop)}
								src="./assets/phones-dialog-BY.png"
								alt={"сегментация базы гостей ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_dialog, mediaContent.image_phone_dialog_tablet)}
								src="./assets/phones-dialog-tablet-BY.png"
								alt={"сегментация базы гостей ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_dialog, mediaContent.image_phone_dialog_mobile)}
								src="./assets/phones-dialog-mobile-BY.png"
								alt={"сегментация базы гостей ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					detailText: 'Посмотреть пример бота',
					detailLink: telegramBotLinkBY
				},
				{
					desktopContentWidth: 530,
					tabName: "Дизайн",
					desktopTitle: "Будь на стиле",
					mobileTitle: "Будь на стиле",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Настраивай изображения и тексты для бота в фирменном
							стиле&nbsp;&mdash; развивай единый образ бренда. Гости, которые
							уже пользовались сайтом или приложением, узнают
							и&nbsp;запоминают заведение.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_design, mediaContent.image_phone_design_desktop)}
								src="./assets/phones-design-BY.png"
								alt={"сегментация базы гостей ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_design, mediaContent.image_phone_design_tablet)}
								src="./assets/phones-design-tablet-BY.png"
								alt={"сегментация базы гостей ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<StaticImage
								className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_design, mediaContent.image_phone_design_mobile)}
								src="./assets/phones-design-mobile-BY.png"
								alt={"сегментация базы гостей ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					detailText: 'Посмотреть пример бота',
					detailLink: telegramBotLinkBY,
				},
			] as Array<FeatureGalleryItem>,
		}
	}

	return {
		features: [
			{
				desktopContentWidth: 510,
				tabName: "Telegram-бот",
				desktopTitle: "Telegram-бот для заказов",
				desktopContent: <>Предложи гостям удобный способ заказа прямо из&nbsp;Telegram. Бот покажет аппетитные фотографии блюд,
					оформит доставку или самовывоз прямо в&nbsp;мессенджере. Назначил встречу с&nbsp;друзьями в&nbsp;одном
					диалоге, в&nbsp;соседнем&nbsp;&mdash; заказал для неё пиццу.</>,
				mobileContent: <>Предложи гостям удобный способ заказа прямо из&nbsp;Telegram. Бот покажет аппетитные фотографии блюд,
					оформит доставку или самовывоз прямо в&nbsp;мессенджере. Назначил встречу с&nbsp;друзьями в одном
					диалоге, в&nbsp;соседнем&nbsp;&mdash; заказал для неё пиццу.</>,
				mediaContent: (
					<>
						<StaticImage
							className={classNames(mediaContent.image, mediaContent.image_logo_telegram)}
							src="./assets/telegram-logo.png"
							alt={"уведомления в приложении для кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</>
				),
				detailText: 'Посмотреть пример бота',
				detailLink: telegramBotLink,
				linkRel: "nofollow"
			},
			{
				desktopContentWidth: 510,
				tabName: "Заказ",
				desktopTitle: "Получай больше заказов",
				mobileTitle: <>Получай<br/>больше заказов</>,
				desktopContent: <>Telegram-бот не отвлекается и не пропустит сообщение гостя. Готов без перерыва и выходных рассказывать о&nbsp;заведении, предлагать меню и оформлять заказы.
					Понравится тем, кто не любит звонить и писать живым сотрудникам.</>,
				mobileContent: <p className={mediaContent.contet_mobile_order}>Telegram-бот не&nbsp;отвлекается и не пропустит сообщение гостя.
					Готов без перерыва и&nbsp;выходных рассказать о&nbsp;заведении, предложить&nbsp;меню и оформить заказ.&nbsp;Привлечет
					тех, кто не&nbsp;любит звонить и&nbsp;писать живым сотрудникам.</p>,
				mediaContent: (
					<>
						<StaticImage
							className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_order, mediaContent.image_phone_desktop)}
							src="./assets/phones-order.png"
							alt={"таргетированные рассылки в приложении"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
						<StaticImage
							className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_order, mediaContent.image_phone_tablet)}
							src="./assets/phones-order-tablet.png"
							alt={"таргетированные рассылки в приложении"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
						<StaticImage
							className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_order, mediaContent.image_phone_mobile)}
							src="./assets/phones-order-mobile.png"
							alt={"таргетированные рассылки в приложении"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</>
				),
				detailText: 'Посмотреть пример бота',
				detailLink: telegramBotLink,
				linkRel: "nofollow"
			},
			{
				desktopContentWidth: 529,
				tabName: "Коммуникация",
				desktopTitle: "Веди диалог",
				mobileTitle: "Веди диалог",
				desktopContent: <>Держи обратную связь с&nbsp;гостями, подключайся отвечай на&nbsp;вопросы и&nbsp;предложения,
					там где не&nbsp;достаточно ответов бота. Для каждого гостя создается отдельный чат, где хранится
					история переписки. Рассылай моментальные уведомления прямо в&nbsp;Telegram, чтобы гости точно заметили
					новую акцию.</>,
				mobileContent: <>Держи обратную связь с&nbsp;гостями, подключайся отвечай на&nbsp;вопросы и&nbsp;предложения,
					там где не достаточно ответов бота. Для каждого гостя создается отдельный&nbsp;чат, где хранится
					история переписки. Рассылай моментальные уведомления прямо&nbsp;в&nbsp;Telegram, чтобы гости точно&nbsp;заметили
					новую акцию.</>,
				mediaContent: (
					<>
						<StaticImage
							className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_dialog, mediaContent.image_phone_dialog_desktop)}
							src="./assets/phones-dialog.png"
							alt={"сегментация базы гостей ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
						<StaticImage
							className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_dialog, mediaContent.image_phone_dialog_tablet)}
							src="./assets/phones-dialog-tablet.png"
							alt={"сегментация базы гостей ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
						<StaticImage
							className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_dialog, mediaContent.image_phone_dialog_mobile)}
							src="./assets/phones-dialog-mobile.png"
							alt={"сегментация базы гостей ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</>
				),
				detailText: 'Посмотреть пример бота',
				detailLink: telegramBotLink,
				linkRel: "nofollow"
			},
			{
				desktopContentWidth: 504,
				tabName: "Дизайн",
				desktopTitle: "Будь собой",
				mobileTitle: "Будь собой",
				desktopContent: <p className={mediaContent.content_phone_design}>Настраивай изображения и&nbsp;тексты для бота в&nbsp;фирменном стиле&nbsp;&mdash; развивай
					единый образ бренда. Гости, которые уже пользовались сайтом или приложением, узнают и&nbsp;запоминают заведение.</p>,
				mobileContent: <>Настраивай изображения и тексты&nbsp;для бота в&nbsp;фирменном стиле&nbsp;&mdash; развивай
					единый образ бренда. Гости, которые уже пользовались сайтом или приложением, узнают и&nbsp;запоминают заведение.</>,
				mediaContent: (
					<>
						<StaticImage
							className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_design, mediaContent.image_phone_design_desktop)}
							src="./assets/phones-design.png"
							alt={"сегментация базы гостей ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
						<StaticImage
							className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_design, mediaContent.image_phone_design_tablet)}
							src="./assets/phones-design-tablet.png"
							alt={"сегментация базы гостей ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
						<StaticImage
							className={classNames(mediaContent.image, mediaContent.image_phone, mediaContent.image_phone_design, mediaContent.image_phone_design_mobile)}
							src="./assets/phones-design-mobile.png"
							alt={"сегментация базы гостей ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</>
				),
				detailText: 'Посмотреть пример бота',
				detailLink: telegramBotLink,
				linkRel: "nofollow"
			},
		] as Array<FeatureGalleryItem>,
	}
}

import React, {useEffect} from "react"
import classNames from "classnames"

import FeaturesGallery from "../../../components/_V2/FeaturesGallery"
import styles from "./style.module.scss"
import { getData } from "./data"

import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function SectionWLTelegramBot(props: Props) {
	const localizationContext = useLocalizationContext();
	const data = getData(localizationContext.locale);

	let features = data.features;

	useEffect(() => {
		features = data.features;
	}, [data]);

	return (
		<div
			className={classNames(styles.wlMobileFeaturesSection, props.className)}
		>
			<FeaturesGallery
				className={styles.wlFeaturesGallery}
				theme="azure"
				features={features}
			/>
		</div>
	)
}

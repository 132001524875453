import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery"
import styles from "./styles.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"
import Backdrop from "./assets/Backdrop"

import {Locales} from "../../../localization/types";

export const getData = (locale: Locales): {
	features: Array<FeatureGalleryItem>
} => {
	if (locale === 'ru-BY') {
		return {
			features: [
				{
					desktopContentWidth: 510,
					tabName: "Сеть заведений",
					desktopTitle: "Всё для развития сети",
					desktopContent: "Готов развить бизнес в сеть? Система поддерживает несколько адресов, городов и высокую нагрузку по заказам. Маршрутизация, зонирование и расчет стоимости доставки происходит автоматически.",
					mediaContent: (
						<StaticImage
							className={styles.interfaceImage}
							src="./assets/wl-crm-1-BY.png"
							alt={"Сеть заведений"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <Backdrop className={styles.backdrop} />,
					detailText: 'Подробнее',
					detailLink: pagesLinks.franchise
				},
				{
					desktopContentWidth: 510,
					tabName: "CRM",
					desktopTitle: "Единая база для бонусов",
					desktopContent:
						"Единая гостевая база и бонусная программа обеспечивают поток гостей при открытии франшизы. Запускай единые программы лояльности, бонусные акции и комбо предложения во всей сети.",
					mediaContent: (
						<StaticImage
							className={styles.interfaceImage}
							src="./assets/wl-crm-2-BY.png"
							alt={"CRM"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <Backdrop className={styles.backdrop} />,
					detailText: 'Подробнее',
					detailLink: pagesLinks.marketing
				},
				{
					desktopContentWidth: 510,
					tabName: "Аналитика",
					desktopTitle: "Думай глобально, действуй локально",
					desktopContent:
						"Аналитика продаж и закупок поможет принимать верные управленческие решения. Отчёты по всей сети, заведению, конкретному сотруднику, блюду или акции.",
					mediaContent: (
						<StaticImage
							className={styles.interfaceImage}
							src="./assets/wl-crm-3-BY.png"
							alt={"Аналитика"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <Backdrop className={styles.backdrop} />,
					detailText: 'Подробнее',
					detailLink: pagesLinks.reports
				},
				{
					desktopContentWidth: 510,
					tabName: "Продвижение",
					desktopTitle: "Первый в поиске",
					desktopContent:
						"Приложение оптимизировано для поиска в магазинах приложений. Ключевые слова и теги позволяют занимать первые строчки в поисковой выдаче и рекомендациях по геолокации пользователя.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.phonesImage}
								src="./assets/wl-crm-4.png"
								alt={"мобильное приложение для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<noindex>
								<StaticImage
									className={styles.phonesImageMobile}
									src="./assets/wl-crm-4-mobile.png"
									alt={"мобильное приложение для ресторана"}
									objectFit={"contain"}
									placeholder={"blurred"}
									quality={90}
								/>
							</noindex>
						</>
					),
					backdropContent: <Backdrop className={styles.backdrop} />,
					detailText: "Начать бесплатно",
					detailLink: pagesLinks.registration,
				},
			] as Array<FeatureGalleryItem>,
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 510,
					tabName: "Мекемелер желісі",
					desktopTitle: <>Франшизалар мен<br />желілер үшін жасалған</>,
					tabletTitle: <>Франшизалар мен желілер үшін жасалған</>,
					mobileTitle: <>Франшизалар мен<br />желілер үшін жасалған</>,
					desktopContent: "Бизнесті желіге дамытуға дайынсыз ба? Жүйе тапсырыстар бойынша бірнеше мекенжайды, қалаларды және жоғары жүктемені қолдайды. Маршруттау, аймақтандыру және жеткізу құнын есептеу автоматты түрде болады.  Маршрутизация, зонирование и расчет стоимости доставки происходит автоматически. ",
					mediaContent: (
						<StaticImage
							className={styles.interfaceImage}
							src="./assets/wl-crm-1-KZ.png"
							alt={"Сеть заведений"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <Backdrop className={styles.backdrop} />,
					detailText: 'Толығырақ',
					detailLink: pagesLinks.franchise
				},
				{
					desktopContentWidth: 510,
					tabName: "CRM",
					desktopTitle: "Бонустар үшін бірыңғай қор",
					desktopContent:
						"Бірыңғай қонақтар қоры және бонустық бағдарлама франшизаны ашқан кезде қонақтар ағынын қамтамасыз етеді. Бірыңғай адалдық бағдарламаларын, бонустық акцияларды және комбо ұсыныстарын бүкіл желі бойынша іске қосыңыз.",
					mediaContent: (
						<StaticImage
							className={styles.interfaceImage}
							src="./assets/wl-crm-2-KZ.png"
							alt={"CRM"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <Backdrop className={styles.backdrop} />,
					detailText: 'Толығырақ',
					detailLink: pagesLinks.marketing
				},
				{
					desktopContentWidth: 510,
					tabName: "Аналитика",
					desktopTitle: "Талдаңыз және басқарыңыз",
					desktopContent:
						"Сату мен сатып алу аналитикасы дұрыс басқарушылық шешімдерді қабылдауға көмектеседі. Бүкіл желі, мекеме, нақты қызметкер, тағам немесе акция бойынша есептер.",
					mediaContent: (
						<StaticImage
							className={styles.interfaceImage}
							src="./assets/wl-crm-3-KZ.png"
							alt={"Аналитика"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <Backdrop className={styles.backdrop} />,
					detailText: 'Толығырақ',
					detailLink: pagesLinks.reports
				},
				{
					desktopContentWidth: 510,
					tabName: "Жылжыту",
					desktopTitle: "Іздеуде бірінші болыңыз",
					desktopContent: "Қосымша қосымшалар дүкенінде іздеу үшін оңтайландырылған. Негізгі сөздер мен тегтер іздеу нәтижелерінде және пайдаланушы геолокациясы бойынша ұсынымдарда бірінші жолдар алуға мүмкіндік береді.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.phonesImage}
								src="./assets/wl-crm-4.png"
								alt={"мобильное приложение для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<noindex>
								<StaticImage
									className={styles.phonesImageMobile}
									src="./assets/wl-crm-4-mobile.png"
									alt={"мобильное приложение для ресторана"}
									objectFit={"contain"}
									placeholder={"blurred"}
									quality={90}
								/>
							</noindex>
						</>
					),
					backdropContent: <Backdrop className={styles.backdrop} />,
					detailText: "Тегін бастау",
					detailLink: pagesLinks.registration,
				},
			] as Array<FeatureGalleryItem>,
		}
	}

	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 510,
					tabName: "Сеть заведений",
					desktopTitle: <>Создано для <br/>франшиз и сетей</>,
					tabletTitle: <>Создано для франшиз и сетей</>,
					mobileTitle: <>Создано для <br/>франшиз и сетей</>,
					desktopContent: "Готов развить бизнес в сеть? Система поддерживает несколько адресов, городов и высокую нагрузку по заказам. Маршрутизация, зонирование и расчет стоимости доставки происходит автоматически. ",
					mediaContent: (
						<StaticImage
							className={styles.interfaceImage}
							src="./assets/wl-crm-1-KZ.png"
							alt={"Сеть заведений"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <Backdrop className={styles.backdrop} />,
					detailText: 'Подробнее',
					detailLink: pagesLinks.franchise
				},
				{
					desktopContentWidth: 510,
					tabName: "CRM",
					desktopTitle: "Единая база для бонусов",
					desktopContent:
						"Единая гостевая база и бонусная программа обеспечивают поток гостей при открытии франшизы. Запускай единые программы лояльности, бонусные акции и комбо предложения во всей сети.",
					mediaContent: (
						<StaticImage
							className={styles.interfaceImage}
							src="./assets/wl-crm-2-KZ.png"
							alt={"CRM"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <Backdrop className={styles.backdrop} />,
					detailText: 'Подробнее',
					detailLink: pagesLinks.marketing
				},
				{
					desktopContentWidth: 510,
					tabName: "Аналитика",
					desktopTitle: "Анализируй и управляй",
					desktopContent:
						"Аналитика продаж и закупок поможет принимать верные управленческие решения. Отчёты по всей сети, заведению, конкретному сотруднику, блюду или акции.",
					mediaContent: (
						<StaticImage
							className={styles.interfaceImage}
							src="./assets/wl-crm-3-KZ.png"
							alt={"Аналитика"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <Backdrop className={styles.backdrop} />,
					detailText: 'Подробнее',
					detailLink: pagesLinks.reports
				},
				{
					desktopContentWidth: 510,
					tabName: "Продвижение",
					desktopTitle: "Будь первым в поиске",
					desktopContent: "Приложение оптимизировано для поиска в магазинах приложений. Ключевые слова и теги позволяют занимать первые строчки в поисковой выдаче и рекомендациях по геолокации пользователя.",
					mobileContent: "Приложение оптимизировано для поиска в магазинах приложений. Ключевые слова и теги позволяют занимать первые строчки в поисковой выдаче и рекомендациях по геолокации пользователя.",
					mediaContent: (
						<>
							<StaticImage
								className={styles.phonesImage}
								src="./assets/wl-crm-4.png"
								alt={"мобильное приложение для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
							<noindex>
								<StaticImage
									className={styles.phonesImageMobile}
									src="./assets/wl-crm-4-mobile.png"
									alt={"мобильное приложение для ресторана"}
									objectFit={"contain"}
									placeholder={"blurred"}
									quality={90}
								/>
							</noindex>
						</>
					),
					backdropContent: <Backdrop className={styles.backdrop} />,
					detailText: "Начать бесплатно",
					detailLink: pagesLinks.registration,
				},
			] as Array<FeatureGalleryItem>,
		}
	}

	return {
		features: [
			{
				desktopContentWidth: 510,
				tabName: "Сеть заведений",
				desktopTitle: "Создано для \nфраншиз и сетей",
				desktopContent:
					"Готов развить бизнес в сеть? Система поддерживает несколько адресов, городов и высокую нагрузку по заказам. Маршрутизация, зонирование и расчет стоимости доставки происходит автоматически. ",
				mediaContent: (
					<StaticImage
						className={styles.interfaceImage}
						src="./assets/wl-crm-1.png"
						alt={"Сеть заведений"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				backdropContent: <Backdrop className={styles.backdrop} />,
				detailText: 'Подробнее',
				detailLink: pagesLinks.franchise
			},
			{
				desktopContentWidth: 510,
				tabName: "CRM",
				desktopTitle: "Бонусы \nпо всей сети",
				desktopContent:
					"Единая гостевая база и бонусная программа обеспечивают поток гостей при открытии франшизы. Запускай единые программы лояльности, бонусные акции и комбо предложения во всей сети.",
				mediaContent: (
					<StaticImage
						className={styles.interfaceImage}
						src="./assets/wl-crm-2.png"
						alt={"CRM"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				backdropContent: <Backdrop className={styles.backdrop} />,
				detailText: 'Подробнее',
				detailLink: pagesLinks.marketing
			},
			{
				desktopContentWidth: 510,
				tabName: "Аналитика",
				desktopTitle: "Думай глобально, действуй локально",
				desktopContent:
					"Аналитика продаж и закупок поможет принимать верные управленческие решения. Отчёты по всей сети, заведению, конкретному сотруднику, блюду или акции.",
				mediaContent: (
					<StaticImage
						className={styles.interfaceImage}
						src="./assets/wl-crm-3.png"
						alt={"Аналитика"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				backdropContent: <Backdrop className={styles.backdrop} />,
				detailText: 'Подробнее',
				detailLink: pagesLinks.reports
			},
			{
				desktopContentWidth: 510,
				tabName: "Продвижение",
				desktopTitle: "Стань первым в выдаче",
				desktopContent:
					"Приложение оптимизировано для поиска в магазинах приложений. Ключевые слова и теги позволяют занимать первые строчки в поисковой выдаче и рекомендациях по геолокации пользователя.",
				mediaContent: (
					<>
						<StaticImage
							className={styles.phonesImage}
							src="./assets/wl-crm-4.png"
							alt={"мобильное приложение для ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
						<noindex>
							<StaticImage
								className={styles.phonesImageMobile}
								src="./assets/wl-crm-4-mobile.png"
								alt={"мобильное приложение для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</noindex>
					</>
				),
				backdropContent: <Backdrop className={styles.backdrop} />,
				detailText: "Начать бесплатно",
				detailLink: pagesLinks.registration,
			},
		] as Array<FeatureGalleryItem>,
	}
}

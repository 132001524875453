import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import mediaContent from "./mediaContent.module.scss"
import classNames from "classnames"

import {Locales} from "../../../localization/types";
export const getData = (locale: Locales): {
	features: Array<FeatureGalleryItem>
} => {
	if (locale === 'ru-BY') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Акции",
					desktopTitle: "Найди подход к каждому",
					mobileTitle: "Найди подход к каждому",
					desktopContent:
						"Настрой канал общения с гостями: используй \nтаргетированные рассылки по полу и возрасту, \nуведомляй о новых акциях через push-сообщения. \nМотивируй делать заказы чаще и вознаграждай \nгостей бонусами.",
					mediaContent: (
						<div
							className={classNames(
								mediaContent.images__container,
								mediaContent.images__container1
							)}
						>
							<StaticImage
								className={mediaContent.image1}
								src="./assets/wl-appsite-1-BY.png"
								alt={"уведомления в приложении для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
				},
				{
					desktopContentWidth: 490,
					tabName: "Бонусы",
					desktopTitle: <>Бонусов много не&nbsp;бывает</>,
					mobileTitle: <>Бонусов много не&nbsp;бывает</>	,
					desktopContent:
						"Возвращай гостей чаще с бонусной программой. Рациональная выгода или чувство джекпота от шестого кофе в подарок? Что за этим стоит — решать только гостю. Одно знаем точно, эти программы отлично работают.",
					mediaContent: (
						<div
							className={classNames(
								mediaContent.images__container,
								mediaContent.images__container2
							)}
						>
							<StaticImage
								className={mediaContent.image2}
								src="./assets/wl-appsite-2-BY.png"
								alt={"уведомления в приложении для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
				},
				{
					desktopContentWidth: 490,
					tabName: "Комбо",
					desktopTitle: "Ккккомбо!",
					mobileTitle: "Ккккомбо!",
					desktopContent: (
						<span className={mediaContent.smallDescription}>
							Выдели отдельную категорию комбо-наборов в&nbsp;приложении.
							Любимые гостями позиции объединены для быстрого заказа.
							Можно оплачивать, скоро все будет готово!
						</span>
					),
					mediaContent: (
						<div
							className={classNames(
								mediaContent.images__container,
								mediaContent.images__container3
							)}
						>
							<StaticImage
								className={mediaContent.image3}
								src="./assets/wl-appsite-3-BY.png"
								alt={"уведомления в приложении для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
				},
				{
					desktopContentWidth: 490,
					tabName: "Всё в CRM",
					desktopTitle: <>Создавай опыт гостя&nbsp;по-новому</>,
					mobileTitle: <>Создай опыт <br/>гостя <br/>по-новому</>,
					desktopContent:
						"Сделай место «своим» для каждого. Единая база гостей и широкий набор инструментов взаимодействия — от электронных карт лояльности до персональных уведомлений. Всё в одной CRM.",
					mediaContent: (
						<div className={mediaContent.images__container}>
							<StaticImage
								className={mediaContent.image4}
								src="./assets/wl-appsite-4-BY.png"
								alt={"уведомления в приложении для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
				},
			] as Array<FeatureGalleryItem>,
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Акциялар",
					desktopTitle: "Әр қонақты қызықтырыңыз",
					desktopContent:
						"Қонақтармен қарым-қатынас арнасын теңшеңіз: жынысы мен жасы бойынша таргеттелген жіберілімдерді пайдаланыңыз, жаңа акциялар туралы push-хабарламалар арқылы хабарлаңыз. Тапсырыстарды жиірек жасауға ынталандырыңыз және оларды бонустармен көтермелеңіз.",
					mediaContent: (
						<div
							className={classNames(
								mediaContent.images__container,
								mediaContent.images__container1
							)}
						>
							<StaticImage
								className={mediaContent.image1}
								src="./assets/wl-appsite-1-BY.png"
								alt={"уведомления в приложении для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
				},
				{
					desktopContentWidth: 490,
					tabName: "Бонустар",
					desktopTitle: <>Жинау ма немесе жарату ма?</>,
					desktopContent: "Бонустық бағдарламаның көмегімен қонақтарды жиірек қайтарыңыз. Ұтымды пайда ма әлде сыйлыққа берілген алтыншы кофеден джекпот сезімі ме? Бұның артында не тұр — қонақ қана шешеді. Нақты білетініміз: мейрамханаларға арналған осы адалдық бағдарламалары тамаша жұмыс істейді.",
					mediaContent: (
						<div
							className={classNames(
								mediaContent.images__container,
								mediaContent.images__container2
							)}
						>
							<StaticImage
								className={mediaContent.image2}
								src="./assets/wl-appsite-2-KZ.png"
								alt={"уведомления в приложении для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
				},
				{
					desktopContentWidth: 490,
					tabName: "Комбо",
					desktopTitle: "Ккккомбо!",
					mobileTitle: "Ккккомбо!",
					desktopContent: (
						<span className={mediaContent.smallDescription}>
						Комбо-жинақтардың бөлек санатын бөлектеңіз. Қонақтарға ұнайтын позициялар мейрамхананың қосымшасы арқылы жылдам тапсырыс беру үшін біріктірілген. Төлеуге болады, бәрі тез арада дайын болады!

					</span>
					),
					mediaContent: (
						<div
							className={classNames(
								mediaContent.images__container,
								mediaContent.images__container3
							)}
						>
							<StaticImage
								className={mediaContent.image3}
								src="./assets/wl-appsite-3-KZ.png"
								alt={"уведомления в приложении для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
				},
				{
					desktopContentWidth: 490,
					tabName: "Барлығы CRM-де",
					desktopTitle: <>Қонақтың тәжірибесін жаңадай жаса</>,
					desktopContent: "Орынды әрқайсысы үшін «өзінікі» етіңіз. Қонақтардың бірыңғай қоры және өзара әрекеттесу құралдарының кең жинағы — электрондық адалдық карталарынан дербес хабарламаларға дейін. Барлығы бір CRM-де.",
					mediaContent: (
						<div className={mediaContent.images__container}>
							<StaticImage
								className={mediaContent.image4}
								src="./assets/wl-appsite-4-KZ.png"
								alt={"уведомления в приложении для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
				},
			] as Array<FeatureGalleryItem>,
		}
	}

	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Акции",
					desktopTitle: "Заинтересуй \nкаждого гостя",
					mobileTitle: "Заинтересуй \nкаждого гостя",
					desktopContent:
						"Настрой канал общения с гостями: используй \nтаргетированные рассылки по полу и возрасту, \nуведомляй о новых акциях через push-сообщения. \nМотивируй делать заказы чаще и вознаграждай \nгостей бонусами.",
					mediaContent: (
						<div
							className={classNames(
								mediaContent.images__container,
								mediaContent.images__container1
							)}
						>
							<StaticImage
								className={mediaContent.image1}
								src="./assets/wl-appsite-1-BY.png"
								alt={"уведомления в приложении для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
				},
				{
					desktopContentWidth: 490,
					tabName: "Бонусы",
					desktopTitle: <>Копить <br/>или тратить?</>,
					tabletTitle: <>Копить или тратить?</>,
					mobileTitle: <>Копить <br/>или тратить?</>,
					desktopContent: "Возвращай гостей чаще с бонусной программой. Рациональная выгода или чувство джекпота от шестого кофе в подарок? Что за этим стоит — решать только гостю. Одно знаем точно: эти программы лояльности для ресторанов отлично работают.",
					tabletContent: "Возвращай гостей чаще с бонусной программой. Рациональная выгода или чувство джекпота \nот шестого кофе в подарок? Что за этим стоит — решать только гостю. Одно знаем точно: эти программы  лояльности для ресторанов отлично работают.",
					mobileContent: "Возвращай гостей чаще с бонусной программой. Рациональная выгода или чувство джекпота от шестого кофе в подарок? Что за этим стоит — решать только гостю. Одно знаем точно: эти программы лояльности для ресторанов отлично работают.",
					mediaContent: (
						<div
							className={classNames(
								mediaContent.images__container,
								mediaContent.images__container2
							)}
						>
							<StaticImage
								className={mediaContent.image2}
								src="./assets/wl-appsite-2-KZ.png"
								alt={"уведомления в приложении для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
				},
				{
					desktopContentWidth: 490,
					tabName: "Комбо",
					desktopTitle: "Ккккомбо!",
					mobileTitle: "Ккккомбо!",
					desktopContent: (
						<span className={mediaContent.smallDescription}>
						Выдели отдельную категорию комбо-наборов. Любимые гостями позиции объединены
							для быстрого заказа через приложение ресторана. Можно оплачивать, скоро всё будет готово!

					</span>
					),
					mediaContent: (
						<div
							className={classNames(
								mediaContent.images__container,
								mediaContent.images__container3
							)}
						>
							<StaticImage
								className={mediaContent.image3}
								src="./assets/wl-appsite-3-KZ.png"
								alt={"уведомления в приложении для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
				},
				{
					desktopContentWidth: 490,
					tabName: "Всё в CRM",
					desktopTitle: <>Создавай опыт <br/>гостя по-новому</>,
					tabletTitle: <>Создавай опыт <br/>гостя по-новому</>,
					mobileTitle: <>Создай опыт <br/>гостя <br/>по-новому</>,
					desktopContent: "Сделай место «своим» для каждого. Единая база гостей и широкий набор инструментов взаимодействия — от электронных карт лояльности до персональных уведомлений. Всё в одной CRM.",
					tabletContent: "Сделай место «своим» для каждого. Единая база гостей и широкий набор инструментов \nвзаимодействия — от электронных карт лояльности до персональных уведомлений. Всё в одной CRM.",
					mobileContent: "Сделай место «своим» для каждого. Единая база гостей и широкий набор инструментов взаимодействия — от электронных карт лояльности до персональных уведомлений. Всё в одной CRM.",
					mediaContent: (
						<div className={mediaContent.images__container}>
							<StaticImage
								className={mediaContent.image4}
								src="./assets/wl-appsite-4-KZ.png"
								alt={"уведомления в приложении для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
				},
			] as Array<FeatureGalleryItem>,
		}
	}
	return {
		features: [
			{
				desktopContentWidth: 490,
				tabName: "Акции",
				desktopTitle: "Найди подход к каждому",
				mobileTitle: "Найди подход к каждому",
				desktopContent:
					"Настрой канал общения с гостями: используй \nтаргетированные рассылки по полу и возрасту, \nуведомляй о новых акциях через push-сообщения. \nМотивируй делать заказы чаще и вознаграждай \nгостей бонусами.",
				mediaContent: (
					<div
						className={classNames(
							mediaContent.images__container,
							mediaContent.images__container1
						)}
					>
						<StaticImage
							className={mediaContent.image1}
							src="./assets/wl-appsite-1.png"
							alt={"уведомления в приложении для кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
			},
			{
				desktopContentWidth: 490,
				tabName: "Бонусы",
				desktopTitle: "Копить \nили тратить?",
				mobileTitle: "Копить \nили тратить?",
				desktopContent:
					"Возвращай гостей чаще с бонусной программой. Рациональная выгода или чувство джекпота от шестого кофе в подарок? Что за этим стоит — решать только гостю. Одно знаем точно, эти программы лояльности для ресторанов отлично работают.",
				mediaContent: (
					<div
						className={classNames(
							mediaContent.images__container,
							mediaContent.images__container2
						)}
					>
						<StaticImage
							className={mediaContent.image2}
							src="./assets/wl-appsite-2.png"
							alt={"уведомления в приложении для кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
			},
			{
				desktopContentWidth: 440,
				tabName: "Комбо",
				desktopTitle: "Ккккомбо!",
				mobileTitle: "Ккккомбо!",
				desktopContent: (
					<span className={mediaContent.smallDescription}>
						Выдели отдельную категорию комбо-наборов. Любимые гостями позиции объединены для быстрого заказа
                        через приложение ресторана. Можно оплачивать, скоро всё будет готово!
					</span>
				),
				mediaContent: (
					<div
						className={classNames(
							mediaContent.images__container,
							mediaContent.images__container3
						)}
					>
						<StaticImage
							className={mediaContent.image3}
							src="./assets/wl-appsite-3.png"
							alt={"уведомления в приложении для кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
			},
			{
				desktopContentWidth: 490,
				tabName: "Всё в CRM",
				desktopTitle: "Создай опыт гостя \nпо-новому",
				mobileTitle: "Создай опыт гостя \nпо-новому",
				desktopContent:
					"Сделай место «своим» для каждого. Единая база гостей и широкий набор инструментов взаимодействия — от электронных карт лояльности до персональных уведомлений. Все в одной CRM.",
				mediaContent: (
					<div className={mediaContent.images__container}>
						<StaticImage
							className={mediaContent.image4}
							src="./assets/wl-appsite-4.png"
							alt={"уведомления в приложении для кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
			},
		] as Array<FeatureGalleryItem>,
	}
}

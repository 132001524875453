import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { pagesLinks, pagesLinksKz } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			// desktopCtaDetails:
			// 	"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом. И две  недели бесплатно.",
			// tabletCtaDetails:
			// 	"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом. И две  недели бесплатно.",
			// mobileCtaDetails:
			// 	"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом. И две  недели бесплатно.",
			pageName: "Приложение и сайт",
			desktopTitle: "Зарабатывай без посредников",
			mobileTitle: "Зарабатывай без посредников",
			subTitle: (
				<p className={styles.description}>
					Создай приложение и сайт своего заведения за&nbsp;1&nbsp;день. Удобный
					интерфейс, доставка, актуальное меню и бонусная программа. Выгодно для
					бизнеса и&nbsp;гостя.
				</p>
			),
			cta: pagesLinks.registration.text,
			ctaLink: {
				text: "Начать бесплатно",
				href: "#popup",
				useGatsbyLink: false,
			},
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/product-presentation-KZ.png"
					alt="мобильное приложение для гостей кафе и ресторанов"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		}
	}

	if (locale === 'kz-KZ') {
		return {
			pageName: "Қосымша және сайт",
			desktopTitle: "Делдалдарсыз ақша табыңыз",
			subTitle: (
				<p className={styles.description}>
					Мекемеңіздің қосымшасын және сайтын 1 күнде жасаңыз.
					Ыңғайлы интерфейс, жеткізу, өзекті мәзір және бонустық бағдарлама.
					Бизнес пен қонақ үшін тиімді.
				</p>
			),
			cta: pagesLinksKz.registration.text,
			ctaLink: {
				text: "Тегін бастау",
				href: "#popup",
				useGatsbyLink: false,
			},
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/product-presentation-KZ.png"
					alt="мобильное приложение для гостей кафе и ресторанов"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		}
	}

	if (locale === 'ru-BY') {
		return {
			// desktopCtaDetails:
			// 	"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом. И две  недели бесплатно.",
			// tabletCtaDetails:
			// 	"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом. И две  недели бесплатно.",
			// mobileCtaDetails:
			// 	"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом. И две  недели бесплатно.",
			pageName: "Приложение и сайт",
			desktopTitle: "Зарабатывай без посредников",
			mobileTitle: "Зарабатывай без посредников",
			subTitle: (
				<p className={styles.description}>
					Создай приложение и сайт своего заведения за&nbsp;1&nbsp;день. Удобный
					интерфейс, доставка, актуальное меню и бонусная программа. Выгодно для
					бизнеса и&nbsp;гостя.
				</p>
			),
			cta: pagesLinks.registration.text,
			ctaLink: {
				text: "Начать бесплатно",
				href: "#popup",
				useGatsbyLink: false,
			},
			figure: (
				<StaticImage
					className={styles.image}
					src="./assets/product-presentation-BY.png"
					alt="мобильное приложение для гостей кафе и ресторанов"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		}
	}
	return {
		pageName: "Мобильное приложение и сайт",
		// desktopCtaDetails:
		// 	"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом. И две  недели бесплатно.",
		// tabletCtaDetails:
		// 	"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом. И две  недели бесплатно.",
		// mobileCtaDetails:
		// 	"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом. И две  недели бесплатно.",
		desktopTitle: "Работай с гостями без посредников",
		mobileTitle: "Работай с гостями без посредников",
		subTitle: (
			<p className={styles.description}>
				Создай приложение и сайт своего заведения за&nbsp;1&nbsp;день. Удобный
				интерфейс, доставка, актуальное меню и бонусная программа. Выгодно для
				бизнеса и&nbsp;гостя.
			</p>
		),
		cta: pagesLinks.registration.text,
		ctaLink: {
			text: "Начать бесплатно",
			href: "#popup",
			useGatsbyLink: false,
		},
		figure: (
			<StaticImage
				className={styles.image}
				src="./assets/product-presentation.png"
				alt="мобильное приложение для гостей кафе и ресторанов"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
	}
}

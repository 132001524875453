import React from "react"

const Backdrop = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="328"
			height="328"
			viewBox="0 0 328 328"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<circle opacity="0.2" cx="164" cy="164" r="164" fill="url(#pattern0)" />
			<defs>
				<pattern
					id="pattern0"
					patternContentUnits="objectBoundingBox"
					width="0.0609756"
					height="0.0625"
				>
					<use xlinkHref="#image0_7_9" transform="scale(0.00152439)" />
				</pattern>
				<image
					fill={"#C4D4ED"}
					id="image0_7_9"
					width="40"
					height="41"
					xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAApCAYAAABHomvIAAAAe0lEQVRYCe3SwQ2AIBBEUdwO3Ya0A2yLjL1QAobIBU+cCImfZG5sGB6EwEIAAQQQQAABBBBAAIFFBMx1mCu3xEVqvTXMFc1V+qRzmZJN7VNQmYKjAubp7J9Xxfb7Gp2fsq8W2ly5xlzr/L8pt+cQBBBAAAEEEEAAgV8KPMJGP4Bm2oVZAAAAAElFTkSuQmCC"
				/>
			</defs>
		</svg>
	)
}
export default Backdrop

import React, { useRef, useEffect, useState } from "react"
import classNames from "classnames"

import FeaturesGallery, {
	FEATURE_GALLERY_DIRECTION,
} from "../../../components/_V2/FeaturesGallery"
import styles from "./style.module.scss"
import { getData } from "./data"

import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function SectionWLMobileFeatures(props: Props) {
	const localizationContext = useLocalizationContext();
	const data = getData(localizationContext.locale);

	let features = data.features;
	let mediaContent = data.mediaContent;

	useEffect(() => {
		features = data.features;
		mediaContent = data.mediaContent;
	}, [data]);

	return (
		<>
			<div className={classNames(styles.wlDeliverySection, props.className)}>
				<FeaturesGallery
					className={styles.wlFeaturesGallery}
					theme="green"
					features={features}
					mediaContent={mediaContent}
					direction={FEATURE_GALLERY_DIRECTION.REVERSE}
				/>
			</div>
		</>
	)
}

import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import classNames from "classnames"
import mainStyles from "./styles.module.scss"

import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-BY') {
		return {
			title: "Уникальность решает",
			description: (
				<>
					Создай имидж проекта через приложение. Мы&nbsp;разработаем кастомный
					дизайн&nbsp;и уникальный опыт пользователя. Сделаем это быстрее
					и дешевле, чем сторонние студии. Плюс, продукт будет нативно
					интегрирован в&nbsp;систему Quick Resto.
				</>
			),
			detailLink: "#",
			detailText: "Заказать дизайн",
			majorFigure: (
				<StaticImage
					className={classNames(
						mainStyles.sectionWlUniqDesignMedia,
						mainStyles.sectionWlUniqDesignMediaLeft,
						mainStyles.sectionWlUniqDesignMediaLeftBy
					)}
					src="./assets/wl-uniq-design-1-BY.png"
					alt=""
				/>
			),
			minorFigure: (
				<StaticImage
					className={mainStyles.sectionWlUniqDesignMedia}
					src="./assets/wl-uniq-design-2-BY.png"
					alt=""
				/>
			),
			mobileFigure: (
				<StaticImage
					className={mainStyles.sectionWlUniqDesignMedia}
					src="./assets/figure-mobile.png"
					alt=""
					objectFit="contain"
				/>
			),
		}
	}

	if (locale === 'kz-KZ') {
		return {
			title: "Сіздің бірегейлігіңіз",
			description: (
				<>
					Қосымша арқылы жоба беделін құрыңыз. Біз пайдаланушының жеке дизайнын және бірегей тәжірибесін әзірлейміз.
					Бұны бөгде студияларға қарағанда тезірек әрі арзанырақ жасаймыз. Оған қоса, өнім Quick Resto жүйесіне табиғи біріктірілетін болады.
				</>
			),
			detailLink: "#",
			detailText: "Дизайнға тапсырыс беру",
			majorFigure: (
				<StaticImage
					className={classNames(
						mainStyles.sectionWlUniqDesignMedia,
						mainStyles.sectionWlUniqDesignMediaLeft
					)}
					src="./assets/wl-uniq-design-1-KZ.png"
					alt=""
				/>
			),
			minorFigure: (
				<StaticImage
					className={mainStyles.sectionWlUniqDesignMedia}
					src="./assets/wl-uniq-design-2-KZ.png"
					alt=""
				/>
			),
			mobileFigure: (
				<StaticImage
					className={mainStyles.sectionWlUniqDesignMedia}
					src="./assets/figure-mobile.png"
					alt=""
					objectFit="contain"
				/>
			),
		}
	}

	if (locale === 'ru-KZ') {
		return {
			title: "Твоя уникальность",
			description: (
				<>
					Создай имидж проекта через приложение. Мы&nbsp;разработаем кастомный
					дизайн&nbsp;и уникальный опыт пользователя. Сделаем это быстрее&nbsp;и&nbsp;дешевле, чем сторонние студии. Продукт&nbsp;будет нативно интегрирован в&nbsp;систему Quick Resto.
				</>
			),
			detailLink: "#",
			detailText: "Заказать дизайн",
			majorFigure: (
				<StaticImage
					className={classNames(
						mainStyles.sectionWlUniqDesignMedia,
						mainStyles.sectionWlUniqDesignMediaLeft
					)}
					src="./assets/wl-uniq-design-1-KZ.png"
					alt=""
				/>
			),
			minorFigure: (
				<StaticImage
					className={mainStyles.sectionWlUniqDesignMedia}
					src="./assets/wl-uniq-design-2-KZ.png"
					alt=""
				/>
			),
			mobileFigure: (
				<StaticImage
					className={mainStyles.sectionWlUniqDesignMedia}
					src="./assets/figure-mobile.png"
					alt=""
					objectFit="contain"
				/>
			),
		}
	}

	return {
		title: "Уникальность решает",
		description: (
			<>
				Создай имидж проекта через приложение. Мы&nbsp;разработаем кастомный
				дизайн и&nbsp;уникальный опыт пользователя. Сделаем это быстрее&nbsp;и дешевле, чем сторонние студии. Плюс, продукт будет нативно
				интегрирован в&nbsp;систему Quick Resto.
			</>
		),
		detailLink: "#",
		detailText: "Заказать дизайн",
		majorFigure: (
			<StaticImage
				className={classNames(
					mainStyles.sectionWlUniqDesignMedia,
					mainStyles.sectionWlUniqDesignMediaLeft
				)}
				src="./assets/wl-uniq-design-1.png"
				alt=""
			/>
		),
		minorFigure: (
			<StaticImage
				className={mainStyles.sectionWlUniqDesignMedia}
				src="./assets/wl-uniq-design-2.png"
				alt=""
			/>
		),
		mobileFigure: (
			<StaticImage
				className={mainStyles.sectionWlUniqDesignMedia}
				src="./assets/figure-mobile.png"
				alt=""
				objectFit="contain"
			/>
		),
	}
}

import React from "react"
import { getData } from "./data"
import ProductPresentationBase from "../../../components/_V2/ProductPresentationBase"
import styles from "./styles.module.scss"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function ProductPresentation(props: Props) {
	const localizationContext = useLocalizationContext()
	const data = getData(localizationContext.locale);

	const Backdrop = () => (
		<>
			<div className={styles.backdrop1} />
			<div className={styles.backdrop2} />
		</>
	)

	return (
		<ProductPresentationBase
			sectionClass={styles.section}
			containerClass={styles.content}
			data={data}
			className={props.className}
			backdrop={Backdrop}
			backdropClass={styles.backdrop}
			popupType="app"
		/>
	)
}

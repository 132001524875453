import React, {useEffect} from "react"
import classNames from "classnames"

import data from "./data"
import FeaturesGallery, {
	FEATURE_GALLERY_DIRECTION,
} from "../../../components/_V2/FeaturesGallery"
import styles from "./style.module.scss"
import { getData } from "./data"

import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function SectionWLAppSiteFeatures(props: Props) {
	const localizationContext = useLocalizationContext();
	const data = getData(localizationContext.locale);

	let features = data.features;

	useEffect(() => {
		features = data.features;
	}, [data]);

	return (
		<div className={classNames(styles.wlSection, props.className)}>
			<FeaturesGallery
				className={styles.wlFeaturesGallery}
				theme="purple-custom-backdrop"
				features={data.features}
				direction={FEATURE_GALLERY_DIRECTION.REVERSE}
				leftColumnClass={styles.leftColumn}
			/>
		</div>
	)
}
